.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 120px);
}

.box {
  max-width: 1230px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .box {
    gap: 14px;
  }
}

@media screen and (max-width: 481px) {
  .title {
    margin-top: 60px;
    margin-bottom: 52px;
  }
}
