:root {
  --background: #fff;
  --offset-base-size: 2px;

  --common-border-radius: 20px;
  --input-border-radius: 15px;
  --button-border-radius: 10px;

  --text-primary-color: #212121;
  --text-secondary-color: #8f92a1;
  --text-additional-color: #aeaeb7;
  --text-white-color: #fff;
  --text-black-color: #1c1c1c;
  --text-input-color: #92929d;
  --text-link-color: #79c6c6;
  --text-option-color: #696974;

  --btn-bgc: #ff5a26;
  --logout-btn-bgc: #fafafb;
  --logout-btn-border: #f1f1f5;
  --header-shadow: #e2e2ea;
  --colors-interface-form: #fff;
  --color-input-focus: #79c6c6;
  --color-input-invalid: #f00;
  --color-checkbox-border: #b5b5be;
  --scroll-track-color: #0a0c10;

  --scrollbar-color: var(--scroll-track-color) var(--logout-btn-border);
  --scrollbar-width: thin;

  --common-transition: all 0.3s ease-in;
}

@-moz-document url-prefix() {
  :root {
    scrollbar-color: var(--scrollbar-color);
  }
}

body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: var(--logout-btn-border);
}
body::-webkit-scrollbar-thumb {
  background: var(--scroll-track-color);
  border: 3px solid var(--logout-btn-border);
  border-radius: 6px;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: var(--text-primary-color);
  background: var(--background);
}

/* Typography classes */

.text {
  margin: 0;
  padding: 0;
}

.text_color_primary {
  color: var(--text-primary-color);
}

.text_color_secondary {
  color: var(--text-secondary-color);
}

.text_color_additional {
  color: var(--text-additional-color);
}

.text_color_black {
  color: var(--text-black-color);
}

.text_color_white {
  color: var(--text-white-color);
}

.text_color_red {
  color: var(--color-input-invalid);
}

.text_color_input {
  color: var(--text-input-color);
}

.text_color_link {
  color: var(--text-link-color);
}

.text_color_option {
  color: var(--text-option-color);
}

.text_type_h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 0.233333px;
}

.text_type_h2-5 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.233333px;
}

.text_type_h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.233333px;
}

.text_type_h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.text_type_large {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.text_type_medium {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}

.text_type_medium-20 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.text_type_medium-18 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.text_type_medium-16 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.text_type_small {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 430px) {
  .text_type_large {
    font-size: 12px;
    line-height: 15px;
  }

  .text_type_h1 {
    font-size: 26px;
    line-height: 31px;
  }

  .text_type_h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .text_type_h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .text_type_medium-20 {
    font-size: 11px;
    line-height: 20px;
  }
}
