.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 150px);
  padding-top: 50px;
  margin-bottom: 50px;
}

.logo {
  width: 68px;
  height: 54px;
}

.title {
  letter-spacing: 0.233333px;
}

.subtitle {
  letter-spacing: 0.1px;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.agreement {
  margin-top: 45px;
  margin-bottom: 20px;
  text-align: center;
}

.footer {
  padding: 16px 20px;
  background-color: var(--logout-btn-bgc);
  border-radius: 0 0 var(--common-border-radius) var(--common-border-radius);
}

.nav {
  text-decoration: none;
  transition: var(--common-transition);
}

.nav:hover {
  opacity: 0.8;
}

@media screen and (max-width: 430px) {
  .subtitle {
    margin-bottom: 40px;
  }

  .agreement {
    margin-top: 65px;
    margin-bottom: 10px;
  }

  .nav {
    display: block;
    max-width: 180px;
    margin: 0 auto;
  }
}
