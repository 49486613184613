.popup {
  position: relative;
  width: 360px;
  height: 253px;
  padding: 25px 20px 20px;
  box-sizing: border-box;
  border-radius: var(--common-border-radius);
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
}

.btn {
  width: 100%;
  margin-top: auto;
}

.close_btn {
  position: absolute;
  top: 17px;
  right: 17px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: var(--common-transition);
}

.close_btn:hover {
  opacity: .7;
}
